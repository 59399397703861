import React, { useState, useEffect } from 'react';
import Services from '../services/Services';
import Navbar from '../../components/navbar/Navbar';
import About from '../about/About';
import Contacts from '../contacts/Contacts';
import "./home.css"
import Footer from '../../components/footer/Footer';
const Home = () => {
    const [isVisible, setIsVisible] = useState(false);
    // const { btnHide } = useAppContext();

    useEffect(() => {
        window.addEventListener('scroll', () => {
            if (window.scrollY > 100) {
                setIsVisible(true);
            } else {
                setIsVisible(false);
            }
        });
    }, []);

    const goTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    };
    return (
        <>
            <div className='contancs-socials'>
                <div className='emailto' href="mailto:adrianavittoriaav@gmail.com">adrianavittoriaav@gmail.com</div>
                <div className='socials'>
                    <div>
                        <a href='https://www.instagram.com/ag.impresa.pulizie?igsh=MTJmd2JkdWE5aGZ0aQ==' target='_black' rel="noopener noreferrer">
                            <img className='social-icons' src='/ag-icons/instagram.png' alt='instagram'></img>
                        </a>
                    </div>
                    <div>
                        <a href='https://www.facebook.com/profile.php?id=61554507544332' target='_black' rel="noopener noreferrer">
                            <img className='social-icons' src='/ag-icons/facebook.png' alt='facebook'></img>
                        </a>
                    </div>
                    <div>
                        <a href="https://wa.me/393669731155?text=Salve%20avrei%20interesse%20a%20ricevere%20un%20preventivo%20per%20i%20vostri%20servizi/prodotti" target='_black'>
                            <img className='social-icons' src='/ag-icons/whatsapp.png' alt='whatsapp'></img>
                        </a>
                    </div>
                </div>
            </div>
            <Navbar />
            <div id='home'>
                <div className='home'>
                    <div className='home-text'>
                        <h1>A.G Impresa di Pulizie</h1>
                        <p>specializzata nella pulizia e sanificazione</p>
                    </div>
                </div>
                <Services />
                <About />
                <Contacts />
                <Footer />
                {isVisible === true ?
                    <div id='scroll-up' className='container-scroll-up-btn' onClick={goTop}>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="scroll-up">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M12 19.5v-15m0 0l-6.75 6.75M12 4.5l6.75 6.75" />
                        </svg>
                    </div>
                    :
                    null}
            </div>
        </>
    );
}

export default Home;
