import Credits from "./pages/credits/Credits";
import Home from "./pages/home/Home";
import { Route, Routes } from "react-router-dom";
import ReactGA from 'react-ga';
import CookieConsent, { Cookies } from "react-cookie-consent";
import { useAppContext } from './context';
import { initGA } from "./utils/ga-utils";

function App() {
  const { getConsent, setGetConsent } = useAppContext();

  const handleAcceptCookie = () => {
    if (getConsent === true || getConsent === undefined) {
      setGetConsent(true);
      initGA(`${process.env.REACT_APP_MEASUREMENT_ID}`);

    }
  };

  const handleDeclineCookie = () => {
    //remove google analytics cookies
    Cookies.remove("_ga");
    Cookies.remove(`_ga_${process.env.REACT_APP_MEASUREMENT_ID}`)
    Cookies.remove("_gat");
    Cookies.remove("_gid");
    Cookies.remove("Cookie-Consent");
    setGetConsent(false)
  };

  return (
    <>
      <Routes>
        <Route path="/" index element={<Home />} />
        <Route path="/credits" element={<Credits />} />
      </Routes>
      {!getConsent || undefined ?

        <CookieConsent
          enableDeclineButton
          flipButtons
          cookieName="Cookie-Consent"
          style={{ backgroundColor: "#ffffff", color: "black", fontFamily: "monospace", opacity: "0.8" }}
          buttonStyle={{ borderRadius: "12px", fontFamily: "monospace" }}
          declineButtonStyle={{ borderRadius: "12px", fontFamily: "monospace" }}
          onAccept={() => {
            // triggered if user accept
            handleAcceptCookie();
            console.log("Accept was triggered by clicking the Accept button");
          }}
          onDecline={() => {
            alert("nay!");
            handleDeclineCookie();
          }}
          expires={1}
        >

          This website uses cookies to enhance the user experience.
        </CookieConsent>
        :
        null
      }
    </>
  );
}

export default App;
