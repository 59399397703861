import React,{ useState, useContext, createContext } from "react";


export const AppContext = createContext();

export const useAppContext = () => useContext(AppContext);

export const AppProvider = ({ children }) => {
    const [getConsent, setGetConsent] = useState(undefined);
    const [navOpen, setNavOpen] = useState(false);
    const [btnHide, setBtnHide] = useState(false);

    return (
        <AppContext.Provider value={{
            setNavOpen,
            setBtnHide,
            getConsent,
            setGetConsent
        }}>
            {children}
        </AppContext.Provider>
    )
}